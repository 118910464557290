import { b2x } from '@b2x/react/src';
import React from 'react';

import { AccountOffcanvasProps, useAccountOffcanvas } from './AccountOffcanvas';
import { CartOffcanvasProps, useCartOffcanvas } from './CartOffcanvas';

interface AppContextInterface {
  AccountOffcanvas: React.FunctionComponentElement<AccountOffcanvasProps>;
  CartOffcanvas: React.FunctionComponentElement<CartOffcanvasProps>;
  footerCopyrightRef: React.RefObject<HTMLDivElement>;
  greyBackgroundPage: boolean;
  headerCheckout: boolean;
  headerFirstRowRef: React.RefObject<HTMLDivElement>;
  headerHeight?: number;
  isFooterCopyrightVisible: boolean;
  isHeaderFirstRowVisible: boolean;
  isTopBarRowRefVisible: boolean;
  minimalFooter: boolean;
  topBarDesktopHeight?: number;
  topBarMobileHeight?: number;
  topBarRowRef: React.RefObject<HTMLDivElement>;
  transparentHeader: boolean;
}

export const [AppContextProvider, useAppContext] = b2x.createContext<AppContextInterface>('AppContext');

interface AppStaticContextInterface {
  setFooterCopyrightRef: React.RefCallback<HTMLDivElement>;
  setGreyBackgroundPage: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderCheckout: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderFirstRowRef: React.RefCallback<HTMLDivElement>;
  setHeaderHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  setMinimalFooter: React.Dispatch<React.SetStateAction<boolean>>;
  setTopBarDesktopHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  setTopBarMobileHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  setTopBarRowRef: React.RefCallback<HTMLDivElement>;
  setTransparentHeader: React.Dispatch<React.SetStateAction<boolean>>;
  showAccountOffcanvas(): void;
  showCartOffcanvas(): void;
}

export const [AppStaticContextProvider, useAppStaticContext] =
  b2x.createContext<AppStaticContextInterface>('AppStaticContext');

interface UseAppContextInitializerProps {}

const useAppContextInitializer = (props: UseAppContextInitializerProps) => {
  const [transparentHeader, setTransparentHeader] = React.useState<boolean>(false);
  const [greyBackgroundPage, setGreyBackgroundPage] = React.useState<boolean>(false);
  const [minimalFooter, setMinimalFooter] = React.useState<boolean>(false);
  const [headerCheckout, setHeaderCheckout] = React.useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = React.useState<number>();
  const [topBarDesktopHeight, setTopBarDesktopHeight] = React.useState<number>();
  const [topBarMobileHeight, setTopBarMobileHeight] = React.useState<number>();

  const [AccountOffcanvas, showAccountOffcanvas] = useAccountOffcanvas();
  const [CartOffcanvas, showCartOffcanvas] = useCartOffcanvas();

  const [footerCopyrightRef, setFooterCopyrightRef, isFooterCopyrightVisible] =
    b2x.useIntersectionObserver<HTMLDivElement>(true);
  const [headerFirstRowRef, setHeaderFirstRowRef, isHeaderFirstRowVisible] =
    b2x.useIntersectionObserver<HTMLDivElement>(true);
  const [topBarRowRef, setTopBarRowRef, isTopBarRowRefVisible] = b2x.useIntersectionObserver<HTMLDivElement>(true);

  const appContext: AppContextInterface = React.useMemo(
    () => ({
      AccountOffcanvas,
      CartOffcanvas,
      footerCopyrightRef,
      greyBackgroundPage,
      headerCheckout,
      headerFirstRowRef,
      headerHeight,
      isFooterCopyrightVisible,
      isHeaderFirstRowVisible,
      isTopBarRowRefVisible,
      minimalFooter,
      topBarDesktopHeight,
      topBarMobileHeight,
      topBarRowRef,
      transparentHeader,
    }),
    [
      AccountOffcanvas,
      CartOffcanvas,
      footerCopyrightRef,
      greyBackgroundPage,
      headerCheckout,
      headerFirstRowRef,
      headerHeight,
      isFooterCopyrightVisible,
      isHeaderFirstRowVisible,
      isTopBarRowRefVisible,
      minimalFooter,
      topBarDesktopHeight,
      topBarMobileHeight,
      topBarRowRef,
      transparentHeader,
    ]
  );

  const appStaticContext: AppStaticContextInterface = React.useMemo(
    () => ({
      setFooterCopyrightRef,
      setGreyBackgroundPage,
      setHeaderCheckout,
      setHeaderFirstRowRef,
      setHeaderHeight,
      setMinimalFooter,
      setTopBarDesktopHeight,
      setTopBarMobileHeight,
      setTopBarRowRef,
      setTransparentHeader,
      showAccountOffcanvas,
      showCartOffcanvas,
    }),
    [setFooterCopyrightRef, setHeaderFirstRowRef, setTopBarRowRef, showAccountOffcanvas, showCartOffcanvas]
  );

  return {
    AppContextProvider,
    AppStaticContextProvider,
    appContext,
    appStaticContext,
  };
};

export interface AppContextProps extends UseAppContextInitializerProps {
  children:
    | React.ReactNode
    | ((appContext: AppContextInterface, appStaticContext: AppStaticContextInterface) => React.ReactNode);
}

export const AppContext = ({ children, ...otherProps }: AppContextProps) => {
  const appContextInitializer = useAppContextInitializer(otherProps);
  return (
    <appContextInitializer.AppContextProvider value={appContextInitializer.appContext}>
      <appContextInitializer.AppStaticContextProvider value={appContextInitializer.appStaticContext}>
        {typeof children === 'function'
          ? children(appContextInitializer.appContext, appContextInitializer.appStaticContext)
          : children}
      </appContextInitializer.AppStaticContextProvider>
    </appContextInitializer.AppContextProvider>
  );
};
