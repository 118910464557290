import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType, HeaderMenuItemContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import logo from './images/logo-desktop.svg';
import { SliderVertical } from './slider/SliderVertical';

const fadeDuration = 200;

export interface DesktopHeaderProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  recalculateHeaderHeight(): void;
  secondRowRef: React.RefObject<HTMLDivElement>;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({ content, recalculateHeaderHeight, secondRowRef, visibleFrom }: DesktopHeaderProps) => {
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();

  const menu = b2x.useMenu('MENU_HEADER_DESKTOP', { populate: { content: true } });

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_HEADER: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible(false);
    setSearchBoxVisible((prevState) => !prevState);
  }, []);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible(false);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return (
    <>
      <TopBar content={content} toggleLocaleBoxVisible={toggleLocaleBoxVisible} visibleFrom={visibleFrom} />
      <FirstRow
        activeCategory={activeCategory}
        content={content}
        localeBoxVisible={localeBoxVisible}
        menu={menu}
        recalculateHeaderHeight={recalculateHeaderHeight}
        searchBoxVisible={searchBoxVisible}
        setActiveCategory={setActiveCategory}
        toggleLocaleBoxVisible={toggleLocaleBoxVisible}
        toggleSearchBoxVisible={toggleSearchBoxVisible}
        visibleFrom={visibleFrom}
      />
    </>
  );
};

interface FirstRowProps {
  activeCategory?: b2x.MenuApiDto;
  content?: b2x.ContentApiDto<HeaderContentType>;
  localeBoxVisible: boolean;
  menu?: b2x.MenuApiDto;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  toggleLocaleBoxVisible(): void;
  toggleSearchBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const FirstRow = ({
  activeCategory,
  content,
  localeBoxVisible,
  menu,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  toggleLocaleBoxVisible,
  toggleSearchBoxVisible,
  visibleFrom,
}: FirstRowProps) => {
  const { isHeaderFirstRowVisible } = useAppContext();
  const { setHeaderFirstRowRef } = useAppStaticContext();

  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classnames('bg-white sticky-top shadow-sm header-first-row py-3', `d-none d-${visibleFrom}-block`)}
      ref={setHeaderFirstRowRef}
    >
      <Container className="header-first-row-wrapper">
        <b2x.Row>
          <b2x.Col className="d-flex">
            <Logo recalculateHeaderHeight={recalculateHeaderHeight} />
          </b2x.Col>
          <b2x.Col className="d-flex align-items-end">
            <Menu
              activeCategory={activeCategory}
              menu={menu}
              setActiveCategory={setActiveCategory}
              subMenuDropdownRef={subMenuDropdownRef}
            />
          </b2x.Col>
          <b2x.Col className="d-flex align-items-end justify-content-end">
            <b2x.Fade delayIn={fadeDuration / 2} duration={fadeDuration} show={isHeaderFirstRowVisible}>
              <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
            </b2x.Fade>
          </b2x.Col>
        </b2x.Row>
      </Container>
      {activeCategory && (
        <SubMenuDropdown
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          subMenuDropdownRef={subMenuDropdownRef}
        />
      )}
      {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
      {localeBoxVisible && <LocaleBox toggleLocaleBoxVisible={toggleLocaleBoxVisible} />}
    </div>
  );
};

interface LocaleBoxProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleBox = ({ toggleLocaleBoxVisible }: LocaleBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleLocaleBoxVisible}>
      <div className="row justify-content-center text-center">
        <div className="col-5">
          <b2x.LocaleForm />
        </div>
      </div>
    </Box>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  return (
    <div className={classnames({ mounted: isMounted }, 'sub-menu-dropdown w-100 py-5')} ref={subMenuDropdownRef}>
      <Container>
        <b2x.Row className="justify-content-center" cols={'auto'}>
          {/* {activeCategory.id} */}
          {activeCategory.children.map((secondLevelCategory) => (
            <b2x.Col className="d-flex flex-column px-5" key={secondLevelCategory.id}>
              {secondLevelCategory.content &&
                b2x.typedContent<HeaderMenuItemContentType>(secondLevelCategory.content, (content) => (
                  <>
                    <div className="mb-4">
                      <b2x.ImageFromContent fluid {...content.body.img} />
                    </div>
                    <div className="fw-bold text-uppercase text-center">
                      {content.body.cta && (
                        <b2x.DeprecatedCta className="text-reset text-decoration-none" cta={content.body.cta}>
                          {content.body.titleA}
                          <span className="text-blue"> {content.body.titleB}</span>
                        </b2x.DeprecatedCta>
                      )}
                    </div>
                  </>
                ))}
              {secondLevelCategory.children.length > 0 && (
                <ul className="list-unstyled">
                  {secondLevelCategory.children.map((thirdLevelCategory) => (
                    <li key={thirdLevelCategory.id}>
                      {thirdLevelCategory.children.length > 0 ? (
                        <>
                          <h6 className="text-uppercase text-blue mb-3">
                            <b2x.router.Link
                              className="text-reset text-decoration-none fw-semi-bold"
                              onClick={close}
                              to={thirdLevelCategory.link}
                            >
                              {thirdLevelCategory.label}
                            </b2x.router.Link>
                          </h6>
                          <ul className="list-unstyled">
                            {thirdLevelCategory.children.map(
                              (fourLevelCategory) =>
                                fourLevelCategory.link && (
                                  <li key={fourLevelCategory.id}>
                                    <b2x.router.Link
                                      className="text-reset text-decoration-none"
                                      onClick={close}
                                      to={fourLevelCategory.link}
                                    >
                                      <span className="small fw-semi-bold">{fourLevelCategory.label}</span>
                                    </b2x.router.Link>
                                  </li>
                                )
                            )}
                          </ul>
                        </>
                      ) : (
                        <b2x.router.Link
                          className="text-uppercase text-blue mb-3 h6 d-block text-decoration-none fw-semi-bold"
                          onClick={close}
                          to={thirdLevelCategory.link}
                        >
                          {thirdLevelCategory.label}
                        </b2x.router.Link>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </b2x.Col>
          ))}
        </b2x.Row>
      </Container>
    </div>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  onCloseButtonClick(): void;
}

const Box = ({ children, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className="box py-5" ref={ref}>
      <Container>
        <div className="d-flex justify-content-end">
          <Button iconEnd={{ name: 'delete', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
        {children}
      </Container>
    </div>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleSearchBoxVisible}>
      <div className="row justify-content-center">
        <div className="col-8">
          <b2x.SimpleSearchForm onSuccess={toggleSearchBoxVisible} />
        </div>
      </div>
    </Box>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  toggleLocaleBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const TopBar = ({ content, toggleLocaleBoxVisible, visibleFrom }: TopBarProps) => {
  const { topBarRowRef } = useAppContext();
  const { setTopBarDesktopHeight, setTopBarRowRef } = useAppStaticContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRowRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRowRef]);

  return (
    <div
      className={`d-none d-${visibleFrom}-block extra-small`}
      ref={setTopBarRowRef}
      style={{
        background: content?.body.firstLine?.backgroundColor ? content.body.firstLine.backgroundColor : '#F6F6F6',
        color: content?.body.firstLine?.textColor ? content.body.firstLine.textColor : '#000000',
      }}
    >
      <Container>
        <b2x.Row>
          <b2x.Col className="d-none d-lg-flex align-items-center justify-content-start" size={4}>
            <LocaleToggle
              textColor={content?.body.firstLine?.textColor ?? '#000000'}
              toggleLocaleBoxVisible={toggleLocaleBoxVisible}
            />
          </b2x.Col>
          <b2x.Col className="d-none d-lg-flex align-items-center justify-content-center" size={4}>
            {content?.body.firstLine?.centerColumn?.sliderVertical?.itemList ? (
              <SliderVertical itemList={content.body.firstLine.centerColumn.sliderVertical.itemList} />
            ) : (
              <span className="extra-small text-uppercase">{t('welcome')}</span>
            )}
          </b2x.Col>
          <b2x.Col className="d-none d-lg-flex align-items-center justify-content-end" size={4}>
            {content?.body.firstLine?.rightColumn?.cta && (
              <b2x.DeprecatedCta
                className="text-reset text-decoration-none d-flex align-items-center"
                cta={content.body.firstLine.rightColumn.cta}
              >
                {content.body.firstLine.rightColumn.cta.icon &&
                  isIconName(content.body.firstLine.rightColumn.cta.icon) && (
                    <Icon className="me-2" name={content.body.firstLine.rightColumn.cta.icon} size={20} />
                  )}
                <span className="extra-small text-uppercase">
                  {b2x.formatHtml(content.body.firstLine.rightColumn.cta.label)}
                </span>
              </b2x.DeprecatedCta>
            )}
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};

interface LocaleToggleProps {
  textColor: string;
  toggleLocaleBoxVisible(): void;
}

const LocaleToggle = ({ textColor, toggleLocaleBoxVisible }: LocaleToggleProps) => {
  const { locale, shippingCountry } = b2x.useAppContext();

  return (
    <Button
      alignItems="center"
      className="text-decoration-none extra-small fw-normal"
      iconStart={{ name: 'language', size: 20 }}
      label={
        locale &&
        shippingCountry && (
          <span>{t('header.sendTo', { country: shippingCountry.name, locale: locale.languageDescriptionLocal })}</span>
        )
      }
      onClick={toggleLocaleBoxVisible}
      size="small"
      style={{ color: textColor }}
      variant="blank"
    />
  );
};

interface TogglesProps {
  toggleSearchBoxVisible(): void;
}

const Toggles = ({ toggleSearchBoxVisible }: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      <div className="hstack gap-3">
        <Button iconEnd={{ name: 'search', size: 25 }} onClick={toggleSearchBoxVisible} variant="blank" />
        {session?.customer ? (
          <b2x.router.Link className="lh-1 text-secondary" to={getPagePath('SITE_ACCOUNT')}>
            <Icon name="profile" size={25} />
          </b2x.router.Link>
        ) : (
          <Button iconEnd={{ name: 'profile', size: 25 }} onClick={showAccountOffcanvas} variant="blank" />
        )}

        {session?.customer ? (
          <b2x.router.Link className="lh-1" to="/account/area/wishlist">
            <Button className="position-relative" variant="blank">
              <Icon name={'wishlist'} size={25} />
              {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-secondary">
                  {(session.wishlist.products ? session.wishlist.products.length : 0) +
                    (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                </span>
              )}
            </Button>
          </b2x.router.Link>
        ) : (
          <Button iconEnd={{ name: 'wishlist', size: 25 }} onClick={showAccountOffcanvas} variant="blank" />
        )}

        <Button className="position-relative" onClick={showCartOffcanvas} variant="blank">
          <Icon name={'cart'} size={25} />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-secondary">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </>
  );
};

interface LogoProps {
  recalculateHeaderHeight(): void;
  small?: boolean;
}

const Logo = ({ recalculateHeaderHeight, small }: LogoProps) => {
  return (
    <b2x.router.Link to="/">
      <b2x.Image onLoad={recalculateHeaderHeight} src={small ? logo : logo} />
    </b2x.router.Link>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item mx-2 mx-xl-4', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  className?: string;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  className,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      <Button
        className={classnames(
          'text-decoration-none fw-bold text-blue p-0 text-nowrap fs-6 align-items-center lh-1',
          {
            active: activeCategory && activeCategory.id === firstLevelCategory.id,
          },
          className
        )}
        label={firstLevelCategory.label}
        onClick={onActivateCategory}
        onMouseEnter={onActivateCategory}
        onMouseLeave={onDeactivateCategory}
        size="small"
        variant="blank"
      />
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  menu?: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({ activeCategory, menu, setActiveCategory, subMenuDropdownRef }: MenuProps) => {
  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
    }
  }, [idActiveCategory, setActiveCategory]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 100);

  return (
    <div className="menu d-flex lh-1">
      {menu?.children.map((firstLevelCategory) => {
        return (
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) &&
          (firstLevelCategory.children.length > 0 ? (
            <DropdownMenuButton
              activeCategory={activeCategory}
              addRef={addRef}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
              removeRef={removeRef}
              setActiveCategory={setActiveCategory}
            />
          ) : (
            <MenuItemContainer
              activeCategory={activeCategory}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
            >
              <b2x.router.Link
                className={classnames(
                  'Button btn btn-link blank lh-1 text-decoration-none text-blue p-0 text-nowrap fw-bold fs-6  align-items-center',
                  {
                    active: activeCategory && activeCategory.id === firstLevelCategory.id,
                  }
                )}
                to={firstLevelCategory.link}
              >
                {firstLevelCategory.label}
              </b2x.router.Link>
            </MenuItemContainer>
          ))
        );
      })}
    </div>
  );
};
