import './BannerSlider.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { SliderContentSection } from '../contentTypes';
import { Slider } from '../slider/Slider';

export interface BannerSliderProps extends SliderContentSection {}

export const BannerSlider = ({ fullHeight, sliderList }: BannerSliderProps) => {
  return (
    <div className="banner-slider">
      <b2x.EqualHeight>
        <Slider
          className={classnames({ 'slider-full-height': fullHeight })}
          navigation
          slidesPerView={1}
          spaceBetween={0}
        >
          {sliderList &&
            sliderList.map((slide, index) => (
              <SwiperSlide key={slide.contentSectionId}>
                {(slideData) => (
                  <div className={'slide'}>
                    <b2x.EqualHeightElement name="slide">
                      <div className="background position-relative h-100 text-center">
                        {slide.video?.src && slide.video.src.length > 1 ? (
                          <b2x.DeprecatedVideoAsBackgroundFromContent {...slide.video} />
                        ) : (
                          <>
                            {/* eslint-disable-next-line react/jsx-no-bind */}
                            <b2x.ImageFromContent {...slide.img} fluid />
                          </>
                        )}
                      </div>
                    </b2x.EqualHeightElement>
                    <div
                      className={classnames(
                        'message d-md-flex h-100 align-items-center',
                        // padding
                        'px-3 py-4',
                        // alignment
                        { 'text-start': slide.copy?.alignment === 'left' },
                        { 'text-center': slide.copy?.alignment === 'center' },
                        { 'text-end': slide.copy?.alignment === 'right' }
                      )}
                    >
                      <div className="container-xxl">
                        <div className="col-lg-5 col-md-5 ps-md-5 ps-lg-4 ps-xl-0">
                          <div className={classnames(`text-${slide.copy?.color}`)}>
                            {slide.copy?.title && (
                              <h3 className="display-1 fw-bold title mb-3">{b2x.formatHtml(slide.copy.title)}</h3>
                            )}
                            {slide.copy?.content && (
                              <div className="description display-2 mb-4">{b2x.formatHtml(slide.copy.content)}</div>
                            )}
                            {slide.copy?.cta && slide.copy.cta.label && (
                              <div>
                                <b2x.DeprecatedCta
                                  className={classnames('btn', `btn-${slide.copy.cta.variant}`)}
                                  cta={slide.copy.cta}
                                >
                                  {b2x.formatHtml(slide.copy.cta.label)}
                                </b2x.DeprecatedCta>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </SwiperSlide>
            ))}
        </Slider>
      </b2x.EqualHeight>
    </div>
  );
};
