import './SliderProducts.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { ProductTile } from '../ProductTile';
import { Slider } from './Slider';
import { SliderButton } from './SliderButton';

export interface SliderProductsProps {
  className?: string;
  products?: Array<b2x.ProductApiDto>;
  title?: string;
}

export const SliderProducts = ({ className, products, title }: SliderProductsProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  return (
    <div className={classNames('slider-products mb-3 mb-lg-5', className)}>
      {title && (
        <div className="container-xxl">
          <div className="text-center mb-4">
            <h3 className="dispaly-2 text-blue">{title && b2x.formatHtml(title)}</h3>
          </div>
        </div>
      )}

      <div className="container-xxl">
        <b2x.EqualHeight>
          <div className="slider-container position-relative">
            <Slider
              navigation
              responsive={{
                lg: {
                  slidesPerView: 4,
                },
                md: {
                  slidesPerView: 2,
                },
              }}
              sliderNextEl={sliderNextButton}
              sliderPrevEl={sliderPrevButton}
              slidesPerView={1}
              spaceBetween={20}
            >
              {products?.map((product, index) => (
                <SwiperSlide key={product.id}>
                  <ProductTile enableExtraInfo product={product} />
                </SwiperSlide>
              ))}
            </Slider>

            <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
            <SliderButton direction="right" innerRef={sliderNextButton} size="small" />
          </div>
        </b2x.EqualHeight>
      </div>
    </div>
  );
};
