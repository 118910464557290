import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import SwiperInterface, { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { Button, ButtonVariant } from '../Button';
import { Container } from '../Container';
import { MiscellaneousContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon, IconName, IconSize, isIconName } from '../Icon';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct({ populate: b2x.appConfig.api?.productPopulate });

  const miscellaneousContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  const [skusDivContainer, scrollToSkusDivContainer] = b2x.useScrollTo(-180);
  const { isFooterCopyrightVisible, isTopBarRowRefVisible } = useAppContext();

  return (
    <Page className="ProductPage" noPaddingBottom>
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <>
              <Container>
                <b2x.AddToCartFormHelper product={product} scope="product">
                  {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                    <b2x.Row gap={0}>
                      <b2x.Col size={{ lg: 6, xs: 12 }}>
                        <div className="position-relative">
                          <div className="wishlist-button-container">
                            <b2x.WishlistButtonHelper product={product} sku={selectedSku}>
                              {({ handleWishlistButtonClick, inWishlist }) => (
                                <Button
                                  className="text-primary"
                                  iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 25 }}
                                  onClick={handleWishlistButtonClick}
                                  variant="blank"
                                />
                              )}
                            </b2x.WishlistButtonHelper>
                          </div>
                          <ProductImageSlider
                            selectedProductVariant={selectedProductVariant}
                            selectedSku={selectedSku}
                          />
                        </div>
                      </b2x.Col>
                      <b2x.Col className="p-3 p-lg-5" size={{ lg: 6, xs: 12 }}>
                        <div className="d-none d-lg-block mb-2">
                          <Breadcrumb hiddenLastItem />
                        </div>
                        {selectedProductVariant.name && (
                          <h1 className="h2 text-blue">{b2x.formatHtml(selectedProductVariant.name)}</h1>
                        )}
                        {/*                   {selectedProductVariant.code && (
                    <p className="text-gray-400 mb-2 extra-small mb-1">
                      {t('product.code')}: {selectedProductVariant.code}
                    </p>
                  )} */}

                        {selectedSku?.name && <p className="small">{selectedSku.name}</p>}
                        {selectedProductVariant.descriptionShort && (
                          <div className="description">{b2x.formatHtml(selectedProductVariant.descriptionShort)}</div>
                        )}
                        <div className="product-price-container my-3">
                          <div className="d-flex mb-2">
                            <b2x.PriceBlock
                              classNames={{ price: 'fs-5', specialPrice: 'fs-5' }}
                              gap={3}
                              priceHelper={priceHelper}
                            />
                            <span className="d-flex align-items-center small ms-2">
                              {t('misc.vat', { value: priceHelper.vat })}
                            </span>
                          </div>
                          {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
                            <div className="small">
                              <b2x.BestPrice priceHelper={priceHelper} />
                            </div>
                          )}
                        </div>
                        {/*   {size && (
                    <div className="mb-3">
                      {t('product.size')} {b2x.formatHtml(size)}
                    </div>
                  )} */}
                        <b2x.Row gap={3}>
                          <b2x.Col size={4}>
                            <b2x.FormGroup {...fieldsHelper.quantity.formGroup} noMarginBottom>
                              <b2x.Select {...fieldsHelper.quantity.select} includeEmptyOption={false} />
                            </b2x.FormGroup>
                          </b2x.Col>
                          {fieldsHelper.skus.formFields.length > 1 && (
                            <b2x.Col size={8}>
                              <div ref={skusDivContainer}>
                                <b2x.FormGroup {...fieldsHelper.skus.formGroup} noMarginBottom>
                                  <b2x.Select {...fieldsHelper.skus.select}>
                                    {fieldsHelper.skus.formFields.map(({ option, sku }) => (
                                      <b2x.Option key={sku.id} {...option} label={sku.name} />
                                    ))}
                                  </b2x.Select>
                                </b2x.FormGroup>
                              </div>
                            </b2x.Col>
                          )}
                          <b2x.Col size={12}>
                            <div className="FormGroup mb-3">
                              <div className="">
                                {/* <b2x.Button {...fieldsHelper.buttons.submit} className="text-uppercase" /> */}
                                <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                  fieldsHelper={fieldsHelper}
                                  selectedSku={selectedSku}
                                />
                              </div>
                            </div>
                          </b2x.Col>
                          <div
                            className={classnames('d-flex')}
                            style={{
                              backgroundImage: miscellaneousContent?.body.productPage?.productConsulting
                                ?.backgroundImage
                                ? `url(${miscellaneousContent.body.productPage.productConsulting.backgroundImage})`
                                : 'none',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                            }}
                          >
                            {miscellaneousContent?.body.productPage?.productConsulting?.icon &&
                              isIconName(miscellaneousContent.body.productPage.productConsulting.icon) && (
                                <Icon
                                  className="me-2"
                                  name={miscellaneousContent.body.productPage.productConsulting.icon as IconName}
                                  size={48}
                                />
                              )}
                            {miscellaneousContent?.body.productPage?.productConsulting?.title && (
                              <div className="py-2">
                                <div className="h5 fw-bold m-0">
                                  {b2x.formatHtml(miscellaneousContent.body.productPage.productConsulting.title)}
                                </div>
                                <div>
                                  {b2x.formatHtml(miscellaneousContent.body.productPage.productConsulting.content)}
                                </div>
                              </div>
                            )}
                          </div>
                          <Accordion product={product} />
                          <b2x.Portal>
                            <b2x.ProductStickyFooter
                              fieldsHelper={fieldsHelper}
                              formik={formik}
                              isVisible={!isFooterCopyrightVisible && !isTopBarRowRefVisible}
                              priceHelper={priceHelper}
                              product={product}
                              scrollToElement={scrollToSkusDivContainer}
                              selectedProductImage={selectedProductVariant.image}
                              selectedSku={selectedSku}
                            />
                          </b2x.Portal>
                        </b2x.Row>
                      </b2x.Col>
                    </b2x.Row>
                  )}
                </b2x.AddToCartFormHelper>
              </Container>
              <b2x.RelatedProducts productId={productContext.selectedProductVariant.id}>
                {({ fetching, relatedProducts }) =>
                  fetching ? (
                    <b2x.Loading />
                  ) : (
                    relatedProducts && relatedProducts.length > 0 && <RelatedProduct products={relatedProducts} />
                  )
                }
              </b2x.RelatedProducts>
            </>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface AccordionProps {
  product: b2x.ProductApiDto;
}

const Accordion = ({ product }: AccordionProps) => {
  return (
    <>
      <b2x.Accordion
        className="border-bottom"
        id="product-details"
        itemBodyClassName="px-0 pb-4 pt-2"
        itemButtonClassName="ps-0 fw-bold"
        itemClassName="border-start-0 border-end-0 border-bottom-0 border-top"
      >
        <b2x.AccordionItem id={`accordion-item-${product.id}`} key={product.id} title={t('product.description')}>
          {b2x.formatHtml(product.description)}
        </b2x.AccordionItem>

        {product.attributes?.map(
          (attribute, index) =>
            attribute.typeCode &&
            attribute.value !== '' &&
            attribute.typeCode !== 'ORMA_COLORE' && (
              <b2x.AccordionItem id={`accordion-item-${index}`} key={attribute.typeCode} title={`${attribute.title}`}>
                {b2x.formatHtml(attribute.value)}
              </b2x.AccordionItem>
            )
        )}
        {product.attributeGroups?.map(
          (attributeGroup) =>
            attributeGroup.code === 'SchedaNutrizionale' && (
              <b2x.AccordionItem
                id={`accordion-item-${attributeGroup.code}`}
                key={attributeGroup.code}
                title={`${attributeGroup.name}`}
              >
                <div className="table-responsive">
                  <b2x.Table className="table-hover m-0">
                    <b2x.TableBody>
                      {attributeGroup.attributes?.map((attribute) => (
                        <tr key={attribute.typeCode}>
                          <th>{attribute.title}</th>
                          <td>{attribute.value}</td>
                        </tr>
                      ))}
                    </b2x.TableBody>
                  </b2x.Table>
                </div>
              </b2x.AccordionItem>
            )
        )}
      </b2x.Accordion>
    </>
  );
};

// interface SkuAttributeFormGroupProps {
//   attribute: {
//     title: string;
//     value?: string;
//   };
//   formGroup: b2x.FormGroupProps;
//   index: number;
//   radios: Array<{ radio: b2x.RadioProps; skus: Array<b2x.SkuApiDto> }>;
// }

// const SkuAttributeFormGroup = ({ attribute, formGroup, index, radios }: SkuAttributeFormGroupProps) => {
//   return (
//     <b2x.FormGroup
//       {...formGroup}
//       className="border-top pt-2 mb-2 pt-md-3 mb-md-3 position-relative size-select-container"
//       label={undefined}
//       noMarginBottom
//     >
//       <div className="form-label small mb-3">
//         <span className="fw-normal">{attribute.title}:</span>&nbsp;
//         {attribute.value ?? t('product.selectSize')}
//       </div>
//       <div className="d-flex">
//         {radios.map(({ radio }) => (
//           <b2x.Radio key={radio.id} {...radio} inline />
//         ))}
//       </div>
//     </b2x.FormGroup>
//   );
// };

interface ProductImageSliderProps {
  selectedProductVariant: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
}

const ProductImageSlider = ({ selectedProductVariant, selectedSku }: ProductImageSliderProps) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperInterface>();

  return (
    <div className="slider-product-page">
      <div className="gallery-slide-container text-center">
        <b2x.Lightbox>
          <Swiper
            modules={[FreeMode, Thumbs, Navigation]}
            navigation
            spaceBetween={0}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          >
            {[
              selectedSku?.image ? selectedSku.image : selectedProductVariant.image,
              ...(selectedProductVariant.alternativeImages ? selectedProductVariant.alternativeImages : []),
            ].map(
              (image, index) =>
                image?.src && (
                  // eslint-disable-next-line react/no-array-index-key
                  <SwiperSlide key={`product-slide-${index}`}>
                    <div className="product-slide-image-container">
                      <b2x.LightboxItem bcomImageId={image.id} src={image.src}>
                        <b2x.Image aspectRatio={b2x.appConfig.productImageAspectRatio} fluid src={image.src} />
                      </b2x.LightboxItem>
                    </div>
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </b2x.Lightbox>
      </div>
      <div className="gallery-thumbnail-container d-none d-lg-flex bg-white pt-4">
        <Swiper
          className="flex-grow-1 auto-width"
          freeMode
          modules={[Thumbs, Navigation, FreeMode]}
          onSwiper={setThumbsSwiper}
          slidesPerView={'auto'}
          spaceBetween={15}
          watchSlidesProgress
        >
          {[
            selectedSku?.image ? selectedSku.image : selectedProductVariant.image,
            ...(selectedProductVariant.alternativeImages ? selectedProductVariant.alternativeImages : []),
          ].map(
            (image, index) =>
              image && (
                // eslint-disable-next-line react/no-array-index-key
                <SwiperSlide key={`product-thumbnail-${index}`}>
                  <b2x.Image
                    aspectRatio={b2x.appConfig.productImageAspectRatio}
                    className="border border-2 border-gray-200 rounded d-block"
                    fluid
                    height={100}
                    src={image.src}
                    width={100}
                  />
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
    </div>
  );
};

interface RelatedProductProps {
  products?: Array<b2x.ProductApiDto>;
}

const RelatedProduct = ({ products }: RelatedProductProps) => {
  return (
    <>
      <div className="bg-secondary py-5">
        <b2x.Listing name="Product page - Related products" products={products}>
          <SliderProducts products={products} title={t('misc.relatedProducts')} />
        </b2x.Listing>
      </div>
    </>
  );
};
