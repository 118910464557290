import './Footer.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { FooterContentType } from './contentTypes';
import { FooterIcons } from './FooterIcons';
import { NewsletterForm } from './forms/NewsletterForm';
import { t } from './i18n/i18n';
import { ListAFromMenu } from './ListAFromMenu';
import { SocialIcons } from './SocialIcons';

export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const windowSize = b2x.useWindowSize();
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState<string>(b2x.getCurrentBreakpoint());

  React.useEffect(() => {
    setCurrentBreakpoint(b2x.getCurrentBreakpoint());
  }, [windowSize, setCurrentBreakpoint]);

  const footerContent = b2x.useContent<FooterContentType>('FOOTER_CONTENT');
  const footerMenu = b2x.useMenu('MENU_FOOTER');
  const footerBottomMenu = b2x.useMenu('MENU_BOTTOM_FOOTER');

  const { session } = b2x.useAppContext();
  const { setFooterCopyrightRef, showAccountOffcanvas } = useAppStaticContext();

  return (
    <footer className="footer">
      <div className="bg-secondary text-blue">
        <FooterIcons />
      </div>
      <div className="bg-blue text-white">
        <Container>
          <div className="footer-second-row pt-3 py-lg-5 border-bottom border-white">
            <b2x.Row className="justify-content-center" gap={0}>
              <b2x.Col size={{ xl: 11, xs: 12 }}>
                <b2x.Row gap={0}>
                  <b2x.Col className="footer-newsletter order-lg-2" size={{ lg: 4, xs: 12 }}>
                    <div className="mb-3 px-3 px-lg-0">
                      <div>
                        <h6 className={classnames('fw-bold mb-3', { h4: 'xs sm md'.includes(currentBreakpoint) })}>
                          {t('misc.newsletterTitle')}
                        </h6>
                        <p>{b2x.formatHtml(footerContent?.body.newsletterSubtitle)}</p>
                      </div>
                      <NewsletterForm className="mb-4" emailFieldStyle="inputGroup" source="website-footer" />
                    </div>
                    <SocialIcons />
                  </b2x.Col>
                  <b2x.Col size={{ lg: 8, xs: 12 }}>
                    <b2x.Accordion
                      columnLayoutFrom="lg"
                      id="footer-accordion"
                      itemBodyClassName="small"
                      itemButtonClassName="bg-transparent text-white fw-bold mb-lg-3 p-3 p-lg-0"
                      itemClassName="bg-transparent"
                    >
                      {footerMenu?.children.map(
                        (column) =>
                          column.label && (
                            <b2x.AccordionItem id={`accordionItem-${column.id}`} key={column.code} title={column.label}>
                              {column.children.map((li) =>
                                li.code === 'SITE_CCN_AREA' ? (
                                  session?.userLogged ? (
                                    li.children.map(
                                      (subLi) =>
                                        subLi.link && (
                                          <div key={subLi.code}>
                                            <b2x.router.Link
                                              className="text-reset text-decoration-none d-block py-1"
                                              to={subLi.link}
                                            >
                                              {subLi.label}
                                            </b2x.router.Link>
                                          </div>
                                        )
                                    )
                                  ) : (
                                    <div key={li.code}>
                                      <b2x.BlankButton className="d-block py-1" onClick={showAccountOffcanvas}>
                                        {t('account.login')}
                                      </b2x.BlankButton>
                                    </div>
                                  )
                                ) : (
                                  li.link && (
                                    <div key={li.code}>
                                      {li.image ? (
                                        <b2x.router.Link className={'mt-2 d-inline-block'} to={li.link}>
                                          <b2x.Image {...li.image} fluid width={100} />
                                        </b2x.router.Link>
                                      ) : (
                                        <b2x.router.Link
                                          className="text-reset text-decoration-none d-block py-1"
                                          to={li.link}
                                        >
                                          {li.label}
                                        </b2x.router.Link>
                                      )}
                                    </div>
                                  )
                                )
                              )}
                            </b2x.AccordionItem>
                          )
                      )}
                    </b2x.Accordion>
                  </b2x.Col>
                </b2x.Row>
              </b2x.Col>
            </b2x.Row>
          </div>
          <div className="footer-third-row py-3 extra-small px-3 px-lg-0" ref={setFooterCopyrightRef}>
            <b2x.Row className="justify-content-between align-items-center" gap={0}>
              <b2x.Col className="d-flex align-items-center order-lg-2 mb-3 mb-lg-0" size={{ lg: 'auto', xs: 12 }}>
                <b2x.PaymentIcons />
              </b2x.Col>
              <b2x.Col>
                <div className="d-none d-lg-block text-uppercase">
                  {footerBottomMenu && <ListAFromMenu menu={footerBottomMenu} textUppercase />}
                </div>
                {footerContent && <div>{b2x.formatHtml(footerContent.body.copyright)}</div>}
              </b2x.Col>
            </b2x.Row>
          </div>
        </Container>
      </div>
    </footer>
  );
};
