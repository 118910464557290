import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { HeaderBannerContentSection } from './contentTypes';

export interface ImageBannerProps extends HeaderBannerContentSection {
  className?: string;
}

export const ImageBanner = ({ className, img, mobileCopyPosition, subtitle, title }: ImageBannerProps) => {
  return (
    <div className={classnames('position-relative d-flex flex-wrap', className)}>
      <div
        className={classnames('container-xxl d-block d-lg-none text-center my-3', {
          'order-2': mobileCopyPosition === 'bottom',
        })}
      >
        <div className="d-flex justify-content-center flex-column">
          {title && <h1 className="text-blue m-0">{b2x.formatHtml(title)}</h1>}
        </div>
      </div>
      {img && <b2x.ImageFromContent {...img} className="w-100 order-1" fluid />}
      <div className="position-absolute top-50 start-50 translate-middle w-100 d-none d-lg-block">
        <div className="container-xxl">
          <div className="d-flex justify-content-center flex-column ps-3">
            {title && <h1 className="text-blue">{b2x.formatHtml(title)}</h1>}
            {subtitle && <p className="fs-4">{b2x.formatHtml(subtitle)}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
