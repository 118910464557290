import './BrandContentSection.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { BrandSection } from './contentTypes';

export interface BrandContentSectionProps extends BrandSection {}

export const BrandContentSection = ({ imageBannerSection, ourBrandSection }: BrandContentSectionProps) => {
  return (
    <>
      <section className="brand-content-section">
        <div className="brand-main-wrapper">
          {imageBannerSection && imageBannerSection.image && (
            <div className="background position-relative h-100 text-center">
              <b2x.ImageFromContent {...imageBannerSection.image} fluid />
            </div>
          )}
          {imageBannerSection && (
            <div className="brand-main-content d-md-flex h-100 align-items-center py-3">
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-6 col-md-8 ps-lg-5">
                    {imageBannerSection.title && (
                      <h3 className={classnames('display-2 mb-3', `text-${imageBannerSection.textColor}`)}>
                        {b2x.formatHtml(imageBannerSection.title)}
                      </h3>
                    )}
                    {imageBannerSection.subtitle && (
                      <h5 className={classnames(`text-${imageBannerSection.textColor}`)}>
                        {b2x.formatHtml(imageBannerSection.subtitle)}
                      </h5>
                    )}
                    {imageBannerSection.cta && imageBannerSection.cta.label && (
                      <b2x.DeprecatedCta
                        className={classnames('btn text-white mt-4', `btn-${imageBannerSection.cta.variant}`)}
                        cta={imageBannerSection.cta}
                      >
                        {b2x.formatHtml(imageBannerSection.cta.label)}
                      </b2x.DeprecatedCta>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {ourBrandSection && (
          <div className="brand-list">
            <div className="container-xxl mb-5">
              <b2x.Row className="justify-content-center bg-gray-100 rounded-3 shadow pt-md-5 py-3" gap={0}>
                <b2x.Col size={{ xl: 11, xs: 12 }}>
                  {(ourBrandSection.title || ourBrandSection.subtitle) && (
                    <div className="mb-3 mb-md-5">
                      {ourBrandSection.title && (
                        <h3 className="fw-semi-bold mb-3 text-center">{b2x.formatHtml(ourBrandSection.title)}</h3>
                      )}
                      {ourBrandSection.subtitle && (
                        <div className="d-none d-lg-block text-center">{b2x.formatHtml(ourBrandSection.subtitle)}</div>
                      )}
                    </div>
                  )}
                  {ourBrandSection.brandList && (
                    <b2x.Row gap={0}>
                      {ourBrandSection.brandList.map((brand, index) => (
                        <div className="col-lg-3 col-md-4 col-6 text-center" key={brand.contentSectionId}>
                          {brand.cta ? (
                            <b2x.DeprecatedCta cta={brand.cta}>
                              <b2x.ImageFromContent {...brand.img} className="mb-3 mb-md-5 px-3" fluid />
                            </b2x.DeprecatedCta>
                          ) : (
                            <b2x.ImageFromContent {...brand.img} className="mb-3 mb-md-5 px-3" fluid />
                          )}
                        </div>
                      ))}
                    </b2x.Row>
                  )}
                </b2x.Col>
              </b2x.Row>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
