import { b2x } from '@b2x/react/src';

import { Container } from './Container';
import { FooterIconsContentType } from './contentTypes';
import { Icon, IconName, isIconName } from './Icon';

export interface FooterIconsProps {
  className?: string;
}

export const FooterIcons = ({ className }: FooterIconsProps) => {
  const footerIconsContent = b2x.useContent<FooterIconsContentType>('FOOTER_ICONS_CONTENT');
  return (
    <div className="footer-icons">
      {footerIconsContent?.body.iconsList && (
        <Container>
          <b2x.Row className="justify-content-center" gap={0}>
            <b2x.Col size={{ xl: 11, xs: 12 }}>
              <b2x.Row gap={0}>
                {footerIconsContent.body.iconsList.map((item, index) => {
                  return (
                    item.name &&
                    isIconName(item.name) && (
                      <b2x.Col className="px-2" key={item.iconTitle} size={{ lg: 3, xs: 12 }}>
                        <div className="footer-icons-item d-flex align-items-center mt-2 py-lg-3">
                          {footerIconsContent.body.iconsList?.length === index + 1}
                          <Icon fontSize={22} name={item.name as IconName} />
                          <h6 className="fw-bold text-uppercase ps-2 m-0 text-start">
                            {footerIconsContent.body.iconsList?.length === index + 1} {item.title}
                          </h6>
                        </div>
                      </b2x.Col>
                    )
                  );
                })}
              </b2x.Row>
            </b2x.Col>
          </b2x.Row>
        </Container>
      )}
    </div>
  );
};
