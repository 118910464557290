import { Resource } from '../i18n';

export const it: Resource = {
  form: {
    addToCartForm: {
      skus: {
        label: 'Seleziona variante',
        placeholder: 'seleziona opzione',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telefono' },
    },
  },
  misc: {
    storeName: 'Ormacare',
  },
  product: {
    promo: 'Promo',
    size: 'Taglia',
  },
  welcome: 'Benvenuto su store-ormacare!',
};
