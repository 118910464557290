import { b2x } from '@b2x/react/src';

import { BrandContentSection } from '../BrandContentSection';
import { ContentRowA } from '../ContentRowA';
import { HomePageContentType } from '../contentTypes';
import { BannerSlider } from '../slider/BannerSlider';
import { ProductCategorySlider } from '../slider/ProductCategorySlider';
import { Page } from './Page';

export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  const page = b2x.usePage<HomePageContentType>({
    populate: {
      products: true,
    },
  });

  return (
    <Page noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <>
        <BannerSlider {...page?.content?.body.sliderMainSection} />
        <ContentRowA fullRow={false} {...page?.content?.body.mainProductSection} />
        <ProductCategorySlider {...page?.content?.body.productCategorySection} />
        <BrandContentSection {...page?.content?.body.brandSection} />
      </>
    </Page>
  );
};
