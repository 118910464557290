import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from '../AppContext';
import { MiscellaneousContentType } from '../contentTypes';

export interface PageProps extends b2x.PageProps {
  backgroundImage?: b2x.contentTypes.ImageContentSection;
  greyBackground?: boolean;
  headerCheckout?: boolean;
  noPaddingBottom?: boolean;
  noPaddingTop?: boolean;
  withGenericBackgroundImage?: boolean;
}

export const Page = ({
  backgroundImage,
  children,
  className,
  greyBackground = false,
  headerCheckout = false,
  noPaddingBottom = false,
  noPaddingTop = false,
  withGenericBackgroundImage = false,
  ...otherProps
}: PageProps) => {
  const { pageReady } = b2x.useAppContext();
  const { setHeaderCheckout } = useAppStaticContext();

  const content = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  React.useEffect(() => {
    pageReady && setHeaderCheckout(headerCheckout);
  }, [pageReady, headerCheckout, setHeaderCheckout]);

  React.useEffect(() => {
    if (greyBackground) {
      document.body.classList.add('bg-gray-100');
    } else {
      document.body.classList.remove('bg-gray-100');
    }
  }, [greyBackground]);

  return (
    <b2x.Page {...otherProps} className={classnames(className, 'h-100')}>
      <div
        className={classnames('Page h-100 position-relative')}
        style={
          withGenericBackgroundImage
            ? {
                backgroundImage: content?.body.backgroundImagePage
                  ? `url(${content.body.backgroundImagePage})`
                  : 'none',
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }
            : undefined
        }
      >
        {backgroundImage && (
          <div className="position-absolute h-100 w-100">
            <b2x.ImageAsBackgroundFromContent {...backgroundImage} />
          </div>
        )}
        <div
          className={classnames(
            'position-relative h-100',
            'py-3 py-xl-4',
            { 'bg-lighter': greyBackground },
            { 'pt-0 pt-xl-0': noPaddingTop },
            { 'pb-0 pb-xl-0': noPaddingBottom }
          )}
        >
          {children}
        </div>
      </div>
    </b2x.Page>
  );
};
