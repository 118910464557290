import './MobileHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo-mobile.svg';
// import { useMmenuOffcanvas } from './Mmenu';
import { useMobileNavigationOffcanvas } from './MobileNavigationOffcanvas';
import { useMobileSimpleSearchOffcanvas } from './MobileSimpleSearchOffcanvas';
import { SliderVertical } from './slider/SliderVertical';

export interface MobileHeaderProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  innerRef: React.RefObject<HTMLDivElement>;
  recalculateHeaderHeight(): void;
  visibleUntil: b2x.Breakpoint;
}

export const MobileHeader = ({ content, innerRef, recalculateHeaderHeight, visibleUntil }: MobileHeaderProps) => {
  // const [Mmenu, showMmenu] = useMmenuOffcanvas({
  //   showAccountOffcanvas: showAccountOffcanvas,
  //   showCartOffcanvas: showCartOffcanvas,
  // });

  const { session } = b2x.useAppContext();

  const [MobileNavigationOffcanvas, showMobileNavigationOffcanvas] = useMobileNavigationOffcanvas();
  const [MobileSimpleSearchOffcanvas, showMobileSimpleSearchOffcanvas] = useMobileSimpleSearchOffcanvas();

  const { transparentHeader } = useAppContext();
  const { showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      <TopBar content={content} visibleUntil={visibleUntil} />
      {/* {Mmenu} */}
      {MobileNavigationOffcanvas}
      {MobileSimpleSearchOffcanvas}
      <b2x.Sticky>
        {({ isSticky }) => (
          <div
            className={classnames(` sticky-top MobileHeader d-block d-${visibleUntil}-none border-bottom`, {
              transparent: transparentHeader && !isSticky,
            })}
            ref={innerRef}
          >
            <Container>
              <b2x.Row className={classnames('align-items-center py-2')} gap={3}>
                <b2x.Col className="d-flex" size="auto">
                  {/* <Button iconEnd={{ name: 'menu', size: 30 }} onClick={showMmenu} variant="blank" /> */}
                  <Button
                    iconEnd={{ name: 'menu', size: 25 }}
                    onClick={showMobileNavigationOffcanvas}
                    variant="blank"
                  />
                </b2x.Col>
                <b2x.Col className="d-flex flex-grow-0 justify-content-start">
                  <b2x.router.Link to="/">
                    <b2x.Image onLoad={recalculateHeaderHeight} src={logo} />
                  </b2x.router.Link>
                </b2x.Col>
                <b2x.Col className="d-flex justify-content-end">
                  <div className="hstack gap-2">
                    <Button
                      iconEnd={{ name: 'search', size: 25 }}
                      onClick={showMobileSimpleSearchOffcanvas}
                      variant="blank"
                    />
                    {session?.customer && (
                      <b2x.router.Link className="lh-1" to="/account/area/wishlist">
                        <Button className="position-relative" variant="blank">
                          <Icon name={'wishlist'} size={25} />
                          {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                            (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                              {(session.wishlist.products ? session.wishlist.products.length : 0) +
                                (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                            </span>
                          )}
                        </Button>
                      </b2x.router.Link>
                    )}

                    <Button className="position-relative" onClick={showCartOffcanvas} variant="blank">
                      <Icon name={'cart'} size={25} />
                      {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                        <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                          {session.cart.itemsNumber}
                        </span>
                      )}
                    </Button>
                  </div>
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        )}
      </b2x.Sticky>
    </>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  visibleUntil: b2x.Breakpoint;
}

const TopBar = ({ content, visibleUntil }: TopBarProps) => {
  const topBarRef = React.useRef<HTMLDivElement>(null);
  const { setTopBarMobileHeight } = useAppStaticContext();

  React.useEffect(() => {
    setTopBarMobileHeight(topBarRef.current?.clientHeight);
  }, [setTopBarMobileHeight, topBarRef]);

  return (
    <div
      className={`text-center d-block d-${visibleUntil}-none position-relative`}
      ref={topBarRef}
      style={{
        background: content?.body.firstLine?.backgroundColor ? content.body.firstLine.backgroundColor : '#F6F6F6',
        color: content?.body.firstLine?.textColor ? content.body.firstLine.textColor : '#000000',
        zIndex: 1030,
      }}
    >
      {content?.body.firstLine?.centerColumn?.sliderVertical?.itemList ? (
        <SliderVertical itemList={content.body.firstLine.centerColumn.sliderVertical.itemList} />
      ) : (
        <span className="extra-small text-uppercase">{t('welcome')}</span>
      )}
    </div>
  );
};
