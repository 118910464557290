export const en = {
  form: {
    addToCartForm: {
      skus: {
        label: 'Select variant',
        placeholder: 'select option',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telephone' },
    },
  },
  misc: {
    storeName: 'Ormacare',
  },
  product: {
    promo: 'Promo',
  },
  welcome: 'Welcome to Ormacare',
};
