import './SliderVertical.scss';

import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { Icon, isIconName } from '../Icon';
import { Slider } from './Slider';

export interface SliderVerticalProps {
  itemList?: Array<{
    cta?: b2x.contentTypes.CtaContentSection;
  }>;
}
export const SliderVertical = ({ itemList }: SliderVerticalProps) => {
  return (
    <Slider
      autoHeight
      className="SliderVertical py-2 py-lg-3"
      sliderAutoPlay={itemList && itemList.length > 1 ? 3000 : undefined}
      slidesPerView={1}
      vertical
    >
      {itemList &&
        itemList.map((item, index) => (
          <SwiperSlide key={item.cta?.label}>
            {(slideData) =>
              item.cta ? (
                <b2x.DeprecatedCta className="text-reset text-decoration-none d-flex align-items-center" cta={item.cta}>
                  {item.cta.icon && isIconName(item.cta.icon) && (
                    <Icon className="me-2" name={item.cta.icon} size={20} />
                  )}
                  <span className="text-secondary extra-small text-uppercase fw-semi-bold">
                    {b2x.formatHtml(item.cta.label)}
                  </span>
                </b2x.DeprecatedCta>
              ) : (
                <></>
              )
            }
          </SwiperSlide>
        ))}
    </Slider>
  );
};
