import './CartPage.scss';

import { b2x } from '@b2x/react/src';

import { t } from '../i18n/i18n';
import { Page } from './Page';

export type CartPageProps = b2x.CartPageProps;

export const CartPage = (props: CartPageProps) => {
  return (
    <Page greyBackground>
      <div className="cart-page">
        <h1 className="text-center h2 mb-4 text-primary">{t('cart.title')}</h1>
        <b2x.CartPage {...props} enableContinueShopping />
      </div>
    </Page>
  );
};
